// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

    ion-content {
        cursor: default;
        --padding-start: 5%;
        --padding-end: 5%;
        //--background: var(--ion-color-secondary);
        //--background: linear-gradient(90deg, var(--ion-color-primary) 0%, var(--ion-color-secondary) 90%, var(--ion-color-secondary) 100%);
        //--background: black;
        --background: var(--ion-color-vibrant-yellow);
        //--background: linear-gradient(110deg, #806246 0%, #CDB798 30%, #806246 100%);
    }
    

	--ion-color-primary: #CFB99A;
	--ion-color-primary-rgb: 207,185,154;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #b6a388;
	--ion-color-primary-tint: #d4c0a4;

	--ion-color-secondary: #686E67;
	--ion-color-secondary-rgb: 104,110,103;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #5c615b;
	--ion-color-secondary-tint: #777d76;

	--ion-color-tertiary: #E69C24;
	--ion-color-tertiary-rgb: 230,156,36;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #ca8920;
	--ion-color-tertiary-tint: #e9a63a;

	--ion-color-success: #456152;
	--ion-color-success-rgb: 69,97,82;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255,255,255;
	--ion-color-success-shade: #3d5548;
	--ion-color-success-tint: #587163;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

	--ion-color-medium: #92949c;
	--ion-color-medium-rgb: 146,148,156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	--ion-color-light: #f4f5f8;
	--ion-color-light-rgb: 244,245,248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;

    --ion-color-vibrant-red: #C25450;
	--ion-color-vibrant-red-rgb: 194,84,80;
	--ion-color-vibrant-red-contrast: #ffffff;
	--ion-color-vibrant-red-contrast-rgb: 255,255,255;
	--ion-color-vibrant-red-shade: #ab4a46;
	--ion-color-vibrant-red-tint: #c86562;

    --ion-color-vibrant-yellow: #E69C24;
	--ion-color-vibrant-yellow-rgb: 230,156,36;
	--ion-color-vibrant-yellow-contrast: #000000;
	--ion-color-vibrant-yellow-contrast-rgb: 0,0,0;
	--ion-color-vibrant-yellow-shade: #ca8920;
	--ion-color-vibrant-yellow-tint: #e9a63a;

    --ion-color-vibrant-green: #005248;
	--ion-color-vibrant-green-rgb: 0,82,72;
	--ion-color-vibrant-green-contrast: #ffffff;
	--ion-color-vibrant-green-contrast-rgb: 255,255,255;
	--ion-color-vibrant-green-shade: #00483f;
	--ion-color-vibrant-green-tint: #1a635a;

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

   ion-content {
       //--padding-top: 30px;
   }

  .ios body {
    --ion-background-color: #000000;
    --ion-background-color-rgb: 0,0,0;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;

    --ion-item-background: #000000;

    --ion-card-background: #1c1c1d;
  }

  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: var(--ion-color-step-150);
    --ion-toolbar-border-color: var(--ion-color-step-250);
  }


  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #121212;
    --ion-background-color-rgb: 18,18,18;

    --ion-text-color: #ffffff;
    --ion-text-color-rgb: 255,255,255;

    --ion-border-color: #222222;

    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;

    --ion-item-background: #1e1e1e;

    --ion-toolbar-background: #1f1f1f;

    --ion-tab-bar-background: #1f1f1f;

    --ion-card-background: #1e1e1e;
  }
}

.ion-color-vibrant-red {
	--ion-color-base: var(--ion-color-vibrant-red);
	--ion-color-base-rgb: var(--ion-color-vibrant-red-rgb);
	--ion-color-contrast: var(--ion-color-vibrant-red-contrast);
	--ion-color-contrast-rgb: var(--ion-color-vibrant-red-contrast-rgb);
	--ion-color-shade: var(--ion-color-vibrant-red-shade);
	--ion-color-tint: var(--ion-color-vibrant-red-tint);
}

.ion-color-vibrant-yellow {
	--ion-color-base: var(--ion-color-vibrant-yellow);
	--ion-color-base-rgb: var(--ion-color-vibrant-yellow-rgb);
	--ion-color-contrast: var(--ion-color-vibrant-yellow-contrast);
	--ion-color-contrast-rgb: var(--ion-color-vibrant-yellow-contrast-rgb);
	--ion-color-shade: var(--ion-color-vibrant-yellow-shade);
	--ion-color-tint: var(--ion-color-vibrant-yellow-tint);
}

.ion-color-vibrant-green {
	--ion-color-base: var(--ion-color-vibrant-green);
	--ion-color-base-rgb: var(--ion-color-vibrant-green-rgb);
	--ion-color-contrast: var(--ion-color-vibrant-green-contrast);
	--ion-color-contrast-rgb: var(--ion-color-vibrant-green-contrast-rgb);
	--ion-color-shade: var(--ion-color-vibrant-green-shade);
	--ion-color-tint: var(--ion-color-vibrant-green-tint);
}
