/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// Leaflet JS
@import "~leaflet/dist/leaflet.css";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&family=Pacifico&family=Qwitcher+Grypen:wght@400;700&display=swap');

.leckerli {
    font-family: 'Leckerli One', cursive;
}
.pacifico {
    font-family: 'Pacifico', cursive;
}
.qwitcher {
    font-family: 'Qwitcher Grypen', cursive;
}
.gloria {
    font-family: 'Gloria Hallelujah', cursive;
}
// spinning loader
.spinner {
    /* Spinner size and color */
    width: 1.5rem;
    height: 1.5rem;
    border-top-color: #444;
    border-left-color: #444;
  
    /* Additional spinner styles */
    animation: spinner 400ms linear infinite;
    border-bottom-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;  
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
  }
  
  /* Animation styles */
  @keyframes spinner {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  
  /* Optional — create your own variations! */
  .spinner-large {
    width: 5rem;
    height: 5rem;
    border-width: 6px;
  }
  
  .spinner-slow {
    animation: spinner 1s linear infinite;
  }
  
  .spinner-blue {
    border-top-color: #09d;
    border-left-color: #09d;
  }